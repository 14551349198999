$(document).on('turbolinks:load', function() {
  function countryTemplateSelection(state) {
    if (!state.id) { return state.text; }
    var data = state.text.split(',');
    var code = data[data.length - 1];
    return $('<span class="fs-xtiny"><span class="flag-icon flag-icon-' + state.id.toLowerCase() + '"></span><span class="ml-2">+' + code + '</span></span>');
  };

  $('.country-phone-prefix-select').select2({
    templateSelection: countryTemplateSelection,
    templateResult: countryTemplateSelection,
    theme: 'bootstrap'
  });
});
